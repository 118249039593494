import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// @material-ui/icons

// React icons

// core components
import Footer from "components/Footer/FooterSchreinersApotheken.jsx";
import Muted from "components/Typography/Muted.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page

//Images
import HeaderSection from "./SchreinersApotheken/Sections/HeaderSection";

class VielenDank extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div>
                <HeaderSection />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.section}>
                        <div className={classes.container}>
                            <Muted>
                                <br/>
                                <br/>
                                <h1>Vielen Dank!</h1>
                                <br/>
                                Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich mit Ihnen inn Verbindung setzen.
                                <br/>
                                <br/>
                                <br/>
                            </Muted>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withStyles(landingPageStyle)(VielenDank);
